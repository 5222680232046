export class Colors {
  primary: string = '#ffa060';
  primary_dark: string = '#001f40';
  secondary: string = '#001f40';
  danger: string = '#ef4453';
  warning: string = '#fdcc17';
  light: string = '#f8f8f8';
  gray: string = '#808080';
  dark: string = '#222';
  black: string = '#001020';
  white: string = '#ffffff';
  primary_contrast: string = '#001020';
  toolbar = '#001f40';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Abracharge';
  appID: string = '';
  appHost: string = 'charge.abralife.com';
  showTitle: boolean = false;
  defaultLang: string = 'en';
  appToken: string = '62d8aee3-7e03-472c-8067-6e78a4ed1497';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  root: string = 'https://scpay.smartcharge.io';
  pushAppID: string = '944eae00-c192-4275-8a90-1b85b6c35a8c';
  pushSenderID: string = '693882642625';
  stationIcon: StationIcon = { height: 40, width: 40 };
  toolbarLogoHeight: string = '1.8em';
  availableLangs: string[] = ['en', 'nb', 'de', 'da', 'sv'];
  privacyPolicy: any = {
    nb: 'https://support.abralife.com/hc/no/sections/6634994780317-Personvernerkl%C3%A6ring-brukervilk%C3%A5r-og-rettigheter',
    en: 'https://support.abralife.com/hc/no/sections/6634994780317-Personvernerkl%C3%A6ring-brukervilk%C3%A5r-og-rettigheter'
  };
  termsAndConditions: any = {
    nb: 'https://support.abralife.com/hc/no/sections/6634994780317-Personvernerkl%C3%A6ring-brukervilk%C3%A5r-og-rettigheter',
    en: 'https://support.abralife.com/hc/no/sections/6634994780317-Personvernerkl%C3%A6ring-brukervilk%C3%A5r-og-rettigheter'
  };
  contact: any = {
    external: false,
    email: 'support@abralife.no',
    phone: '+47 23 96 02 51',
    //web: 'current.eco',
    customerService: {
      en: 'https://support.abralife.com'
    }
  };
  constructor() {}
}
interface StationIcon {
  height: number;
  width: number;
}
